import React, { useState, useEffect, useRef } from 'react';
import ChosenDoc from './ChosenDoc';
import DocsList from './DocsList';
import { auth, fbfsdb } from "../../services/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { getDoc, doc } from 'firebase/firestore';
import { Helmet } from 'react-helmet';

function Stories({bookData, fontSize, darkMode}) {
  const [nowloading, setNowLoading] = useState("loading");
  const [docLoading, setDocLoading] = useState('docloading');
  const [docToSee, setDocToSee] = useState('');
  const [chapterToSee, setChapterToSee] = useState(null);
  const [docContent, setDocContent] = useState(false);
  const [docIsSelected, setDocIsSelected] = useState('docnotselected');
  const [highlights, setHighlights] = useState(false);
  const [user] = useAuthState(auth);
  const [storyStatus, setStoryStatus] = useState(null);
  const [audioVisible, setAudioVisible] = useState(false);
  const [language, setLanguage] = useState(false);
  const [availableChapters, setAvailableChapters] = useState(false);
  const firstRun = useRef(true);
  const prevDoc = useRef();
  const prevChapter = useRef();

  // when selecting a document from doc list, update docIsSelected, docToSee and chapterToSee(1)
  const selectDoc = (chosendoc) => {
    setDocContent(false)
    if (chosendoc !== '') {
      setNowLoading('loading');
      setDocLoading('docloading');
    }
    setDocIsSelected('docisselected');
    if (chosendoc !== prevDoc['current']) {
      setChapterToSee(1)
      setDocToSee(chosendoc)
    }
    else {
      setNowLoading('notloading')
      setDocLoading('docnotloading')
    }
  }

  // on first load of stories component, update state to match URL
  useEffect(() => {
    if (firstRun.current) {
      let documentpath = window.location.pathname.replace('/read/', '').replace("/read", "");
      let currchosendoc
      if (documentpath.length > 0) {
        let docArray = documentpath.split("/");
        if (docArray[0].length > 0) {
          currchosendoc = decodeURI(docArray[0]);
          setDocToSee(currchosendoc);
          setDocIsSelected('docisselected');
          if (docArray[1]) {
            if (Number.isInteger(parseInt(docArray[1]))) {
              setChapterToSee(parseInt(docArray[1]));
            }
            else {
              setChapterToSee(1);
            }
          }
          else {
            setChapterToSee(1);
          }
        }
        else {
          setDocToSee('Genesis');
          setChapterToSee(1);
          setDocIsSelected('docnotselected');
        }
      }
      else {
        setDocToSee('Genesis');
        setChapterToSee(1);
        setDocIsSelected('docnotselected');
      }
      let query = window.location.search;
      const urlParams = new URLSearchParams(query);
      if (urlParams.has('highlights')) {
        console.log(urlParams.get('highlights'))
        setHighlights(urlParams.get('highlights'))
      }
      firstRun.current = false;
      return;
    }
  }, [])
  
  useEffect(() => {
    const obtainDoc = async() => {
      try {
        if (docToSee && chapterToSee && (prevDoc['current'] !== docToSee || prevChapter['current'] !== chapterToSee)) {
          setNowLoading('loading');
          setDocLoading('docloading');
          console.log(`Obtaining data for: ${docToSee} ${chapterToSee}`)
          if (Object.keys(highlights).length > 0) {
            window.history.replaceState(null, "", `/read/${encodeURI(docToSee)}/${encodeURI(chapterToSee)}?highlights=${encodeURIComponent(highlights)}`)
          }
          else {
            window.history.replaceState(null, "", `/read/${encodeURI(docToSee)}/${encodeURI(chapterToSee)}`)
          }
          // update url
          let ac = Array.from({length: parseInt(bookData[docToSee]['chapterCount'])}, (_, i) => ({value: i + 1, label: i+1}))
          setAvailableChapters(ac)
          setLanguage(bookData[docToSee]['language'])
          let story = await getDoc(doc(fbfsdb, `documents/${docToSee === 'Matthew' ? 'matthew' : docToSee}_${chapterToSee}`));
          let storyData = story.data();
          setStoryStatus('live');
          setDocContent(storyData['item']['data'])
        }
        setNowLoading('notloading');
      }
      catch (err) {
        console.log(err)
        setDocContent([])
        setNowLoading('notloading')
        setDocLoading('docnotloading')
      }
    }
    obtainDoc().then().catch(err => console.log(err));
    prevDoc.current = docToSee;
    prevChapter.current = chapterToSee;
  }, [docToSee, chapterToSee, highlights, docIsSelected, bookData])

  const updateChapter = (val) => {
    console.log('updating chapter')
    setDocContent(false);
    setChapterToSee(val)
  }

  return (
    <div id='documentpage' className={audioVisible ? 'audioVisible' : ''}>
      <div id='docviewer'>
        <Helmet>
          <title>{`Bibliacra - ${docToSee.replaceAll("_", " ")} ${chapterToSee}`}</title>
        </Helmet>
        <DocsList setHighlights={setHighlights} docIsSelected={docIsSelected} selectDoc={selectDoc} chosenDoc={docToSee} loading={nowloading} documentList={Object.keys(bookData)} setDocIsSelected={setDocIsSelected} setChapterToSee={updateChapter} setDocToSee={setDocToSee} darkMode={darkMode} fontSize={fontSize}/>
        <ChosenDoc user={user?.uid} highlights={highlights} docIsSelected={docIsSelected} setHighlights={setHighlights} selectDoc={selectDoc} chosenDoc={docToSee} docContent={docContent} docLoading={docLoading} storyStatus={storyStatus} setAudioVisible={setAudioVisible} language={language} chapterToSee={chapterToSee} setChapterToSee={updateChapter} availableChapters={availableChapters} setDocIsSelected={setDocIsSelected} darkMode={darkMode} fontSize={fontSize} setDocLoading={setDocLoading}/>
      </div>
    </div>
  )
}

export default Stories