import React from 'react'
import { auth, fbfsdb, LoginOptions } from "../../services/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
// eslint-disable-next-line
import { useEffect, useState } from "react";
import { logout } from '../../services/firebase';
// import default_photo from '../../assets/def_prof_pic.jpeg'
import { NavLink } from "react-router-dom";
import { CiSettings } from 'react-icons/ci'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure
} from '@chakra-ui/react';
import ReactSlider from 'react-slider'
import { doc, getDoc, deleteField, updateDoc } from '@firebase/firestore';

function NavBar({setFontSize, fontSize, darkMode, setDarkMode, subscriptionType, setSubscriptionType}) {
    const [userSignedIn, setUserSignedIn] = useState(null)
    const [user, loading] = useAuthState(auth);
    const [showModal, setShowModal] = useState('hide');
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [currentFontVal, setCurrentFontVal] = useState(false);
    const [settingsView, setSettingsView] = useState('main');
    const [optedIn, setOptedIn] = useState(false);

    const openSettings = () => {
        onOpen()
    }
    
    useEffect(() => {
        switch (fontSize) {
            case 'small':
                // update
                setCurrentFontVal(1);
                break;
            case 'smallmed':
                setCurrentFontVal(2);
                break;
            case 'med':
                setCurrentFontVal(3);
                break;
            case 'medlarge':
                setCurrentFontVal(4);
                break;
            case 'large':
                setCurrentFontVal(5);
                break;
            default:
                break;
        }
    }, [fontSize])

    const closeSettings = () => {
        // reset view
        setSettingsView('main')
        onClose()
    }

    useEffect(() => {
        const obtainUD = async () => {
            let userdoc = await getDoc(doc(fbfsdb, `users/${user?.uid}`));
            let userdata = userdoc.data();
            if (userdata['subscriptionType']) {
                setOptedIn(true);
                if (userdata['subscriptionType'] === 'standard') {
                    setSubscriptionType('standard');
                }
                else if (userdata['subscriptionType'] === 'oneYear') {
                    setSubscriptionType('oneYear');
                }
            }
        }

        if (user) {
            obtainUD().then().catch(err => console.log(err));
        }
    }, [user, subscriptionType])

    const toggleOptedIn = async() => {
        if (optedIn) {
            setOptedIn(false);
            // update opted in to false (remove field for subscriptionType)
            await updateDoc(doc(fbfsdb, `users/${user?.uid}`), {
                subscriptionType: deleteField()
            });
            setSubscriptionType(false);
        }
        else {
            setOptedIn(true);
            // updated opted in to true (add field for subscriptionType === 'standard')
            await updateDoc(doc(fbfsdb, `users/${user?.uid}`), {
                subscriptionType: 'standard'
            });
            setSubscriptionType('standard')
        }
    },
    toggleSubscriptionType = async() => {
        if (subscriptionType === 'standard') {
            setSubscriptionType('oneYear')
            await updateDoc(doc(fbfsdb, `users/${user?.uid}`), {
                subscriptionType: 'oneYear'
            });
        }
        if (subscriptionType === 'oneYear') {
            setSubscriptionType('standard')
            await updateDoc(doc(fbfsdb, `users/${user?.uid}`), {
                subscriptionType: 'standard'
            });
        }
    }
    
    useEffect(() => {
        if (user) {
            setSettingsView('main')
            setUserSignedIn(
                <>
                    {/* allow user to opt into daily emails */}
                    {/* allow user to select the kind of emails they receive */}
                    <div>
                        <label className={'bibliacraupdates'}>
                            <input type={'checkbox'} checked={optedIn} onChange={((e) => {toggleOptedIn()})}/>
                            <span>Get daily readings from Bibliacra</span>
                        </label>
                        {
                            // subscription type
                            optedIn && subscriptionType &&
                            <div>
                                <label className={'bibliacraupdates'}>
                                    <input type={'checkbox'} checked={subscriptionType === 'standard' ? true : false} onChange={((e) => {toggleSubscriptionType()})}/>
                                    <span>Standard (1 Chapter of Hebrew and Greek, daily)</span>
                                </label>
                                <label className={'bibliacraupdates'}>
                                    <input type={'checkbox'} checked={subscriptionType === 'oneYear' ? true : false} onChange={((e) => {toggleSubscriptionType()})}/>
                                    <span>1 Year Plan (read it all in 1 year)</span>
                                </label>
                            </div>
                        }

                    </div>
                    <div className='settingsitem bibsacbtn' onClick={logout}>Sign out</div>
                </>
            )
        }
        else {
            setUserSignedIn(
                <div className='settingsitem bibsacbtn' onClick={((e) => {setSettingsView('login')})}>Sign in</div>
            )
        }
    }, [user, loading, settingsView, optedIn, subscriptionType])

  return (
    <div id="navbar">
        <div id="navbarfull">
            <NavLink className={({ isActive }) => "nav-link" + (isActive ? " active" : "") + (darkMode ? ' darkmode' : '')} to="/">Home</NavLink>
            <NavLink className={({ isActive }) => "nav-link" + (isActive ? " active" : "") + (darkMode ? ' darkmode' : '')} to="/read">Read</NavLink>
            <NavLink className={({ isActive }) => "nav-link" + (isActive ? " active" : "") + (darkMode ? ' darkmode' : '')} to="/daily">Daily</NavLink>
        </div>
        <CiSettings id='settingsgear' onClick={(() => {openSettings()})}/>
        {isOpen &&
            <Modal isOpen={isOpen} onClose={closeSettings}>
                <ModalOverlay />
                <ModalContent className={'settingsmodalcontent' + (darkMode ? ' darkmode' : '')}>
                <ModalHeader>Settings</ModalHeader>
                <ModalCloseButton />
                <ModalBody id='chakramodalsettingsbody'>
                    <div id='mainsettingscontainer'>
                        <div id='settingscontainer' className={settingsView}>
                            <input id="lightdarktoggle" className="lightdarktoggle settingsitem" type="checkbox" checked={darkMode ? false : true} onChange={((e) => {
                                e.target.checked ? localStorage.setItem('darkMode', false) : localStorage.setItem('darkMode', true)
                                e.target.checked ? setDarkMode(false) : setDarkMode(true)
                            })}></input>
                            <div id='fontsizecontainer'>
                                <div className='fontsizeexample small'>AΑא</div>
                                <ReactSlider
                                        min={1}
                                        max={5}
                                        className="horizontal-slider"
                                        thumbClassName="example-thumb"
                                        trackClassName="example-track"
                                        value={currentFontVal}
                                        onChange={((e) => {
                                            setCurrentFontVal(e)
                                            switch(e) {
                                                case 1:
                                                    localStorage.setItem('fontSize', 'small')
                                                    setFontSize('small')
                                                    break;
                                                case 2:
                                                    localStorage.setItem('fontSize', 'smallmed')
                                                    setFontSize('smallmed')
                                                    break;
                                                case 3:
                                                    localStorage.setItem('fontSize', 'med')
                                                    setFontSize('med')
                                                    break;
                                                case 4:
                                                    localStorage.setItem('fontSize', 'medlarge')
                                                    setFontSize('medlarge')
                                                    break;
                                                case 5:
                                                    localStorage.setItem('fontSize', 'large')
                                                    setFontSize('large')
                                                    break;
                                                default:
                                                    break;
                                            }
                                        })}
                                    />
                                <div className='fontsizeexample large'>AΑא</div>
                            </div>
                            {userSignedIn}
                        </div>
                        <div id='logincontainer' className={settingsView}>
                            <p id='logininfo'>When you sign in, you'll automatically start receiving daily reading exercises. You can always change or disable this feature here in the Bibliacra settings.</p>
                            <LoginOptions />
                        </div>
                    </div>
                </ModalBody>
                </ModalContent>
            </Modal>
        }

        <div onClick={() => {setShowModal('hide')}} id="navmodal" className={showModal}>
            <ul id="menu" className={(darkMode ? ' darkmode' : '')}>
                <NavLink className={({ isActive }) => "nav-link" + (isActive ? " active" : "") + (darkMode ? ' darkmode' : '')} to="/">Home</NavLink>
                <NavLink className={({ isActive }) => "nav-link" + (isActive ? " active" : "") + (darkMode ? ' darkmode' : '')} to="/read">Read</NavLink>
                <NavLink className={({ isActive }) => "nav-link" + (isActive ? " active" : "") + (darkMode ? ' darkmode' : '')} to="/daily">Daily</NavLink>
            </ul>
        </div>
        <div id="navigationmenu">
            <div onClick={() => {setShowModal('show')}} id="nav_options_menu" className='openmodal'>&#xe5d2;</div>
        </div>
    </div>
  )
}

export default NavBar
