import React, { useEffect, useState } from 'react'
import { auth, fbfsdb, LoginOptions } from "../../services/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  Spinner,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow
} from '@chakra-ui/react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';

function Daily({fontSize, darkMode, subscriptionType, setSubscriptionType}) {
    const [user, loading] = useAuthState(auth);
    const [gettingData, setGettingData] = useState(false);
    const [dataToRead, setDataToRead] = useState(null);
    // user's daily reading should be rendered entirely in window.
    // see: https://github.com/anlinguist/biblia-sacra/issues/3

    const getMonthShortName = (monthNumber) => {
      const date = new Date();
      date.setMonth(monthNumber);
      return date.toLocaleString('en-US', {month: 'short'});
    }
    // if user isn't logged in, there should be a modal to sign in/sign up.
    useEffect(() => {
      if (loading) {
        return
      }
      if (user) {
        // identify what subscription type the user is on based on subscriptionType on user firebase
        // document.
        // if subscriptionType === 'standard', get the current day and get the passages
        // based on the current day from the database. Then get and render the passages.

        const obtainData = async () => {
          setGettingData(true);
          let userdoc = await getDoc(doc(fbfsdb, `users/${user?.uid}`));
          let userdata = userdoc.data();
          if (subscriptionType) {
            if (subscriptionType === 'standard') {
              // get the current day
              let userReadingDay = parseInt(userdata['currentDay']);
              if (userReadingDay > 0) {
                userReadingDay = userReadingDay - 1;
              }
              console.log(userReadingDay, typeof userReadingDay)
              let readingDoc = await getDoc(doc(fbfsdb, `events/standard`));
              let readingData = readingDoc.data();
              let hebrewData = readingData['hebrew'];
              let greekData = readingData['greek'];

              let hebrewIndex = userReadingDay % 929
              let greekIndex = userReadingDay % 260

              let currentHebrewPassage = hebrewData[hebrewIndex];
              let currentGreekPassage = greekData[greekIndex];
              
              let currentHebrewRef = currentHebrewPassage.replaceAll(' ', '_').replaceAll('Matthew', 'matthew');
              let currentGreekRef = currentGreekPassage.replaceAll(' ', '_').replaceAll('Matthew', 'matthew');
              console.log(currentHebrewRef, currentGreekRef)
              let hebrewPassageDoc = await getDoc(doc(fbfsdb, `documents/${currentHebrewRef}`));
              let greekPassageDoc = await getDoc(doc(fbfsdb, `documents/${currentGreekRef}`));
              let hebrewPassageData = hebrewPassageDoc.data();
              let greekPassageData = greekPassageDoc.data();
              console.log(hebrewPassageData, greekPassageData)
              let hebrewPassageObj = {
                passage: currentHebrewPassage,
                data: hebrewPassageData['item']['data'],
                language: 'hebrew'
              }
              let greekPassageObj = {
                passage: currentGreekPassage,
                data: greekPassageData['item']['data'],
                language: 'greek'
              }
              setDataToRead([hebrewPassageObj, greekPassageObj]);
              setGettingData(false);
            }
            else if (subscriptionType === 'oneYear') {
              let oneYearSS = await getDoc(doc(fbfsdb, `events/oneYear`));
              let oneYearData = oneYearSS.data();
              let currentDate = new Date();
              let currentMonth = getMonthShortName(currentDate.getUTCMonth())
              let currentDay = currentDate.getDate();
              let oneYearPassages = false
              if (oneYearData && oneYearData[currentMonth] && oneYearData[currentMonth][currentDay]) {
                  oneYearPassages = oneYearData[currentMonth][currentDay]
              }
              let formattedPassages = {}
              for (const [language, rawPassages] of Object.entries(oneYearPassages)) {
                  let passagesPreChapterSplit
                  if (rawPassages.includes(",")) {
                      passagesPreChapterSplit = rawPassages.replace("Matthew", "matthew").split(', ')
                  }
                  else {
                      passagesPreChapterSplit = [rawPassages.replace("Matthew", "matthew")]
                  }
          
                  const passagesSplitIntoChapters = [].concat(...passagesPreChapterSplit.map(string => {
                      const [book, range] = string.split(" ");
                      if (!range.includes("-")) {
                          return [string.replaceAll(" ", "_")];
                      }
                      if (range.includes(":")) {
                          return [string.replaceAll(" ", "_")];
                      }
                      const [start, end] = range.split("-").map(Number);
                      return Array.from({ length: end - start + 1 }, (_, i) => `${book}_${start + i}`);
                  }));
          
                  formattedPassages[language] = passagesSplitIntoChapters
              }
              const hebrewTexts = formattedPassages['hebrew'];
              const greekTexts = formattedPassages['greek'];

              console.log(hebrewTexts, greekTexts)

              let oneYear = []
              for (let hebrewText in hebrewTexts) {
                let documentToLookUp;
                let split = false;
                if (hebrewTexts[hebrewText].includes(":")) {
                    split = hebrewTexts[hebrewText].split(":")
                    documentToLookUp = split[0]
                }
                else {
                    documentToLookUp = hebrewTexts[hebrewText]
                }
                let bookChaptRef = await getDoc(doc(fbfsdb, `documents/${documentToLookUp}`));
                let bookChaptData = bookChaptRef.data()['item']['data'];
                let passageRef;
                let passageArray;
        
                if (split) {
                    const [start, end] = split[1].split("-").map(Number);
                    const startindex = bookChaptData.findIndex(item => item.verse === `${start}`);
                    const endindex = bookChaptData.findIndex(item => item.verse === `${end}`);
                    let slicedData = bookChaptData.slice(startindex, endindex + 1)
                    passageRef = `${documentToLookUp.replaceAll("matthew", "Matthew").replaceAll("_", " ")}:${split[1]}`
                    passageArray = slicedData
                }
                else {
                    passageRef = `${documentToLookUp.replaceAll("matthew", "Matthew").replaceAll("_", " ")}`
                    passageArray = bookChaptData
                }

                let passageObj = {
                  passage: passageRef,
                  data: passageArray,
                  language: 'hebrew'
                }
                oneYear.push(passageObj)
              }
              for (let greekText in greekTexts) {
                let documentToLookUp;
                let split = false;
                if (greekTexts[greekText].includes(":")) {
                    split = greekTexts[greekText].split(":")
                    documentToLookUp = split[0]
                }
                else {
                    documentToLookUp = greekTexts[greekText]
                }
                let bookChaptRef = await getDoc(doc(fbfsdb, `documents/${documentToLookUp}`));
                let bookChaptData = bookChaptRef.data()['item']['data'];
                let passageRef;
                let passageArray;

                if (split) {
                    const [start, end] = split[1].split("-").map(Number);
                    const startindex = bookChaptData.findIndex(item => item.verse === `${start}`);
                    const endindex = bookChaptData.findIndex(item => item.verse === `${end}`);
                    let slicedData = bookChaptData.slice(startindex, endindex + 1)
                    passageRef = `${documentToLookUp.replaceAll("matthew", "Matthew").replaceAll("_", " ")}:${split[1]}`
                    passageArray = slicedData
                }
                else {
                    passageRef = `${documentToLookUp.replaceAll("matthew", "Matthew").replaceAll("_", " ")}`
                    passageArray = bookChaptData
                }

                let passageObj = {
                  passage: passageRef,
                  data: passageArray,
                  language: 'greek'
                }
                oneYear.push(passageObj)
              }

            //   let currentHebrewPassage = hebrewData[userReadingDay];
            //   let currentGreekPassage = greekData[userReadingDay];
              setDataToRead(oneYear);
              setGettingData(false);
            }
          }
          else {
            setGettingData(false);
            // user doesn't have a subscriptionType, so they're not subscribed.
            // Open a new modal to inform them that they need to subscribe.
            // Show them the subscription options, and allow them to subscribe.
            // Then, once they subscribe, update their subscriptionType in the database.
            // Then, detect the subscriptionType and render the appropriate data.
          }
        }
        obtainData().catch((err) => {
          console.log(err);
        })
      }

    }, [user, loading, subscriptionType])

    const capitalize = (s) => {
      if (typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1)
    },
      getWordAtts = (language, word_obj) => {
        let word_obj_keys = Object.keys(word_obj)
        let priority = [
          'morphology',
          'gloss',
          'notes'
        ]
        let sorted_word_obj_keys = word_obj_keys.sort(function(a, b) {
          let orderA = priority.indexOf(a);
          let orderB = priority.indexOf(b);
          if (orderA !== -1 && orderB !== -1) {
            return orderA - orderB;
          } else if (orderA !== -1) {
            return -1;
          } else if (orderB !== -1) {
            return 1;
          } else {
            return a.localeCompare(b);
          }
        });

        const getGender = (val) => {
          switch (val) {
            case 'b':
              return 'Both'
            case 'c':
              return 'Common'
            case 'f':
              return 'Feminine'
            case 'm':
              return 'Masculine'
            default:
              break;
          }
        }

        const getNumber = (val) => {
          switch (val) {
            case 'd':
              return 'Dual'
            case 'p':
              return 'Plural'
            case 's':
              return 'Singular'
            default:
              break;
          }
        }
        const getState = (val) => {
          switch (val) {
            case 'a':
              return 'Absolute'
            case 'c':
              return 'Construct'
            case 'd':
              return 'Determined'
            default:
              break;
          }
        };
        const getAdjectiveType = (val) => {
          switch (val) {
            case 'a':
              return 'Adjective'
            case 'c':
              return 'Cardinal Number'
            case 'g':
              return 'Gentilic'
            case 'o':
              return 'Ordinal Number'
            default:
              break;
          }
        }
        const getNounType = (val) => {
          switch (val) {
            case 'c':
              return 'Common'
            case 'g':
              return 'Gentilic'
            case 'p':
              return 'Proper Noun'
            default:
              break;
          }
        }
        const getPronounType = (val) => {
          switch (val) {
            case 'd':
              return 'Demonstrative'
            case 'f':
              return 'Indefinite'
            case 'i':
              return 'Interrogative'
            case 'p':
              return 'Personal'
            case 'r':
              return 'Relative'
            default:
              break;
          }
        }

        return (
          <div>
            {sorted_word_obj_keys.map((att, index) => {
              if (att.startsWith("variant") || att === "word") {
                return false
              }
              else if (att === "morphology" && language === 'hebrew') {
                // parse out hebrew morphology, e.g. HTd/Ncbsa and  HR/Ncfsa
                let morphoCode = word_obj[att]
                let morphoCodeLanguage = morphoCode[0] === "H" ? 'Hebrew' : 'Aramaic';
                morphoCode = morphoCode.slice(1);

                let morphoCodeArr = morphoCode.split('/');
                let parsedMorphology = []

                // loop through split morpho code,
                for (let i = 0; i < morphoCodeArr.length; i++) {
                  let currentPartMorph = morphoCodeArr[i]
                  let parsedMorph = {}

                  let POS = currentPartMorph[0]
                  switch (POS) {
                    case 'V':
                      parsedMorph['Part of Speech'] = 'Verb';
                      let vStem = currentPartMorph[1]
                      if (morphoCodeLanguage === 'Hebrew') {
                        switch (vStem) {
                          case 'q':
                            parsedMorph['Verb Stem'] = 'Qal'
                            break;
                          case 'N':
                            parsedMorph['Verb Stem'] = 'Niphal'
                            break;
                          case 'p':
                            parsedMorph['Verb Stem'] = 'Piel'
                            break;
                          case 'P':
                            parsedMorph['Verb Stem'] = 'Pual'
                            break;
                          case 'h':
                            parsedMorph['Verb Stem'] = 'Hiphil'
                            break;
                          case 'H':
                            parsedMorph['Verb Stem'] = 'Hophal'
                            break;
                          case 't':
                            parsedMorph['Verb Stem'] = 'Hithpael'
                            break;
                          case 'o':
                            parsedMorph['Verb Stem'] = 'Polel'
                            break;
                          case 'O':
                            parsedMorph['Verb Stem'] = 'Polal'
                            break;
                          case 'r':
                            parsedMorph['Verb Stem'] = 'Hithpolel'
                            break;
                          case 'm':
                            parsedMorph['Verb Stem'] = 'Poel'
                            break;
                          case 'M':
                            parsedMorph['Verb Stem'] = 'Poal'
                            break;
                          case 'k':
                            parsedMorph['Verb Stem'] = 'Palel'
                            break;
                          case 'K':
                            parsedMorph['Verb Stem'] = 'Pulal'
                            break;
                          case 'Q':
                            parsedMorph['Verb Stem'] = 'Qal Passive'
                            break;
                          case 'l':
                            parsedMorph['Verb Stem'] = 'Pilpel'
                            break;
                          case 'L':
                            parsedMorph['Verb Stem'] = 'Polpal'
                            break;
                          case 'f':
                            parsedMorph['Verb Stem'] = 'Hithpalpel'
                            break;
                          case 'D':
                            parsedMorph['Verb Stem'] = 'Nithpael'
                            break;
                          case 'j':
                            parsedMorph['Verb Stem'] = 'Pealal'
                            break;
                          case 'i':
                            parsedMorph['Verb Stem'] = 'Pilel'
                            break;
                          case 'u':
                            parsedMorph['Verb Stem'] = 'Hothpaal'
                            break;
                          case 'c':
                            parsedMorph['Verb Stem'] = 'Tiphil'
                            break;
                          case 'v':
                            parsedMorph['Verb Stem'] = 'Hishtaphel'
                            break;
                          case 'w':
                            parsedMorph['Verb Stem'] = 'Nithpalel'
                            break;
                          case 'y':
                            parsedMorph['Verb Stem'] = 'Nithpoel'
                            break;
                          case 'z':
                            parsedMorph['Verb Stem'] = 'Hithpoel'
                            break;
                          default:
                            break;
                        }
                      }
                      else if (morphoCodeLanguage === 'Aramaic') {
                        switch (vStem) {
                          case 'q':
                            parsedMorph['Verb Stem'] = 'Peal'
                            break;
                          case 'Q':
                            parsedMorph['Verb Stem'] = 'Peil'
                            break;
                          case 'u':
                            parsedMorph['Verb Stem'] = 'Hithpeel'
                            break;
                          case 'p':
                            parsedMorph['Verb Stem'] = 'Pael'
                            break;
                          case 'P':
                            parsedMorph['Verb Stem'] = 'Ithpaal'
                            break;
                          case 'M':
                            parsedMorph['Verb Stem'] = 'Hithpaal'
                            break;
                          case 'a':
                            parsedMorph['Verb Stem'] = 'Aphel'
                            break;
                          case 'h':
                            parsedMorph['Verb Stem'] = 'Haphel'
                            break;
                          case 's':
                            parsedMorph['Verb Stem'] = 'Saphel'
                            break;
                          case 'e':
                            parsedMorph['Verb Stem'] = 'Shaphel'
                            break;
                          case 'H':
                            parsedMorph['Verb Stem'] = 'Hophal'
                            break;
                          case 'i':
                            parsedMorph['Verb Stem'] = 'Ithpeel'
                            break;
                          case 't':
                            parsedMorph['Verb Stem'] = 'Hishtaphel'
                            break;
                          case 'v':
                            parsedMorph['Verb Stem'] = 'Ishtaphel'
                            break;
                          case 'w':
                            parsedMorph['Verb Stem'] = 'Hithaphel'
                            break;
                          case 'o':
                            parsedMorph['Verb Stem'] = 'Polel'
                            break;
                          case 'z':
                            parsedMorph['Verb Stem'] = 'Ithpoel'
                            break;
                          case 'r':
                            parsedMorph['Verb Stem'] = 'Hithpolel'
                            break;
                          case 'f':
                            parsedMorph['Verb Stem'] = 'Hithpalpel'
                            break;
                          case 'b':
                            parsedMorph['Verb Stem'] = 'Hephal'
                            break;
                          case 'c':
                            parsedMorph['Verb Stem'] = 'Tiphel'
                            break;
                          case 'm':
                            parsedMorph['Verb Stem'] = 'Poel'
                            break;
                          case 'l':
                            parsedMorph['Verb Stem'] = 'Palpel'
                            break;
                          case 'L':
                            parsedMorph['Verb Stem'] = 'Ithpalpel'
                            break;
                          case 'O':
                            parsedMorph['Verb Stem'] = 'Ithpolel'
                            break;
                          case 'G':
                            parsedMorph['Verb Stem'] = 'Ittaphal'
                            break;
                          default:
                            break;
                        }
                      }
                      let vConjType = currentPartMorph[2]
                      switch (vConjType) {
                        case 'a':
                          parsedMorph["Verb Conjugation Type"] = 'Infinitive Absolute'
                          break;
                        case 'c':
                          parsedMorph["Verb Conjugation Type"] = 'Infinitive Construct'
                          break;
                        case 'p':
                          parsedMorph["Verb Conjugation Type"] = 'Perfect'
                          if (currentPartMorph[3] && currentPartMorph[3] !== 'x') parsedMorph["Person"] = currentPartMorph[3];
                          if (currentPartMorph[4] && currentPartMorph[4] !== 'x') parsedMorph["Gender"] = getGender(currentPartMorph[4]);
                          if (currentPartMorph[5] && currentPartMorph[5] !== 'x') parsedMorph["Number"] = getNumber(currentPartMorph[5]);
                          break;
                        case 'q':
                          parsedMorph["Verb Conjugation Type"] = 'Sequential Perfect'
                          if (currentPartMorph[3] && currentPartMorph[3] !== 'x') parsedMorph["Person"] = currentPartMorph[3];
                          if (currentPartMorph[4] && currentPartMorph[4] !== 'x') parsedMorph["Gender"] = getGender(currentPartMorph[4]);
                          if (currentPartMorph[5] && currentPartMorph[5] !== 'x') parsedMorph["Number"] = getNumber(currentPartMorph[5]);
                          break;
                        case 'i':
                          parsedMorph["Verb Conjugation Type"] = 'Imperfect'
                          if (currentPartMorph[3] && currentPartMorph[3] !== 'x') parsedMorph["Person"] = currentPartMorph[3];
                          if (currentPartMorph[4] && currentPartMorph[4] !== 'x') parsedMorph["Gender"] = getGender(currentPartMorph[4]);
                          if (currentPartMorph[5] && currentPartMorph[5] !== 'x') parsedMorph["Number"] = getNumber(currentPartMorph[5]);
                          break;
                        case 'w':
                          parsedMorph["Verb Conjugation Type"] = 'Sequential Imperfect'
                          if (currentPartMorph[3] && currentPartMorph[3] !== 'x') parsedMorph["Person"] = currentPartMorph[3];
                          if (currentPartMorph[4] && currentPartMorph[4] !== 'x') parsedMorph["Gender"] = getGender(currentPartMorph[4]);
                          if (currentPartMorph[5] && currentPartMorph[5] !== 'x') parsedMorph["Number"] = getNumber(currentPartMorph[5]);
                          break;
                        case 'h':
                          parsedMorph["Verb Conjugation Type"] = 'Cohortative'
                          if (currentPartMorph[3] && currentPartMorph[3] !== 'x') parsedMorph["Person"] = currentPartMorph[3];
                          if (currentPartMorph[4] && currentPartMorph[4] !== 'x') parsedMorph["Gender"] = getGender(currentPartMorph[4]);
                          if (currentPartMorph[5] && currentPartMorph[5] !== 'x') parsedMorph["Number"] = getNumber(currentPartMorph[5]);
                          break;
                        case 'j':
                          parsedMorph["Verb Conjugation Type"] = 'Jussive'
                          if (currentPartMorph[3] && currentPartMorph[3] !== 'x') parsedMorph["Person"] = currentPartMorph[3];
                          if (currentPartMorph[4] && currentPartMorph[4] !== 'x') parsedMorph["Gender"] = getGender(currentPartMorph[4]);
                          if (currentPartMorph[5] && currentPartMorph[5] !== 'x') parsedMorph["Number"] = getNumber(currentPartMorph[5]);
                          break;
                        case 'r':
                          parsedMorph["Verb Conjugation Type"] = 'Participle Active'
                          if (currentPartMorph[3] && currentPartMorph[3] !== 'x') parsedMorph["Gender"] = getGender(currentPartMorph[4]);
                          if (currentPartMorph[4] && currentPartMorph[4] !== 'x') parsedMorph["Number"] = getNumber(currentPartMorph[4]);
                          if (currentPartMorph[5] && currentPartMorph[5] !== 'x') parsedMorph["State"] = getState(currentPartMorph[5]);
                          break;
                        case 's':
                          parsedMorph["Verb Conjugation Type"] = 'Participle Passive'
                          if (currentPartMorph[3] && currentPartMorph[3] !== 'x') parsedMorph["Gender"] = getGender(currentPartMorph[4]);
                          if (currentPartMorph[4] && currentPartMorph[4] !== 'x') parsedMorph["Number"] = getNumber(currentPartMorph[4]);
                          if (currentPartMorph[5] && currentPartMorph[5] !== 'x') parsedMorph["State"] = getState(currentPartMorph[5]);
                          break;
                        default:
                          break;
                      }
                      break;
                    case 'A':
                      // takes type, gender, number, and state next four values)
                      parsedMorph['Part of Speech'] = 'Adjective';
                      if (currentPartMorph[1] && currentPartMorph[1] !== 'x') parsedMorph["Adjective Type"] = getAdjectiveType(currentPartMorph[1]);
                      if (currentPartMorph[2] && currentPartMorph[2] !== 'x') parsedMorph["Gender"] = getGender(currentPartMorph[2]);
                      if (currentPartMorph[3] && currentPartMorph[3] !== 'x') parsedMorph["Number"] = getNumber(currentPartMorph[3]);
                      if (currentPartMorph[4] && currentPartMorph[4] !== 'x') parsedMorph["State"] = getState(currentPartMorph[4]);
                      break;
                    case 'C':
                      parsedMorph['Part of Speech'] = 'Conjunction';
                      break;
                    case 'D':
                      parsedMorph['Part of Speech'] = 'Adverb';
                      break;
                    case 'N':
                      // get noun type (second letter)
                      parsedMorph['Part of Speech'] = 'Noun';
                      if (currentPartMorph[1] !== 'x') parsedMorph['Noun Type'] = getNounType(currentPartMorph[1]);
                      if (currentPartMorph[1] !== 'p') {
                        if (currentPartMorph[2] && currentPartMorph[2] !== 'x') parsedMorph["Gender"] = getGender(currentPartMorph[2]);
                        if (currentPartMorph[3] && currentPartMorph[3] !== 'x') parsedMorph["Number"] = getNumber(currentPartMorph[3]);
                        if (currentPartMorph[4] && currentPartMorph[4] !== 'x') parsedMorph["State"] = getState(currentPartMorph[4]);
                      }
                      break;
                    case 'P':
                      parsedMorph['Part of Speech'] = 'Pronoun';
                      // get type, person, gender, number (next 4 values)
                      if (currentPartMorph[1] && currentPartMorph[1] !== 'x') parsedMorph['Pronoun Type'] = getPronounType(currentPartMorph[1]);
                      if (currentPartMorph[2] && currentPartMorph[2] !== 'x') parsedMorph["Person"] = currentPartMorph[2];
                      if (currentPartMorph[3] && currentPartMorph[3] !== 'x') parsedMorph["Gender"] = getGender(currentPartMorph[3]);
                      if (currentPartMorph[4] && currentPartMorph[4] !== 'x') parsedMorph["Number"] = getNumber(currentPartMorph[4]);
                      break;
                    case 'R':
                      parsedMorph['Part of Speech'] = 'Preposition'
                      if (currentPartMorph[1] && currentPartMorph[1] !== 'x') parsedMorph['Definite Article'] = 'Attached'
                      break;
                    case 'S':
                      parsedMorph['Part of Speech'] = 'Suffix'
                      // get type
                      let suffixType = currentPartMorph[1]
                      switch (suffixType) {
                        case 'd':
                          parsedMorph['Suffix Type'] = 'Directional He'
                          break;
                        case 'h':
                          parsedMorph['Suffix Type'] = 'Paragogic He'
                          break;
                        case 'n':
                          parsedMorph['Suffix Type'] = 'Paragogic Nun'
                          break;
                        case 'p':
                          parsedMorph['Suffix Type'] = 'Pronominal'
                          if (currentPartMorph[2] && currentPartMorph[2] !== 'x') parsedMorph["Person"] = currentPartMorph[2];
                          if (currentPartMorph[3] && currentPartMorph[3] !== 'x') parsedMorph["Gender"] = getGender(currentPartMorph[3]);
                          if (currentPartMorph[4] && currentPartMorph[4] !== 'x') parsedMorph["Number"] = getNumber(currentPartMorph[4]);
                          break;
                        default:
                          break;
                      }
                      break;
                    case 'T':
                      parsedMorph['Part of Speech'] = 'Particle'
                      let particleType = currentPartMorph[1]
                      switch (particleType) {
                        case 'a':
                          parsedMorph['Particle Type'] = 'Affirmation'
                            break;
                        case 'd':
                          parsedMorph['Particle Type'] = 'Definite Article'
                            break;
                        case 'e':
                          parsedMorph['Particle Type'] = 'Exhortation'
                          break;
                        case 'i':
                          parsedMorph['Particle Type'] = 'Interrogative'
                          break;
                        case 'j':
                          parsedMorph['Particle Type'] = 'Interjection'
                          break;
                        case 'm':
                          parsedMorph['Particle Type'] = 'Demonstrative'
                          break;
                        case 'n':
                          parsedMorph['Particle Type'] = 'Negative'
                          break;
                        case 'o':
                          parsedMorph['Particle Type'] = 'Direct Object Marker'
                          break;
                        case 'r':
                          parsedMorph['Particle Type'] = 'Relative'
                          break;
                        default:
                          break;
                      }
                      break;
                    default:
                      break;
                  }
                  parsedMorphology.push(parsedMorph)
                }
                
                let a = (parsedMorphology.map((wordPieceParsing, wordPieceIndex) => {
                  let b = (Object.keys(wordPieceParsing).map((wordPieceAtt, wordPieceAttIndex) => {
                    return (
                      <div className={'wordattandval ' + fontSize} key={`${index}${wordPieceIndex}${wordPieceAttIndex}`}><span className='wordatt'>{capitalize(wordPieceAtt)}</span><span>: </span><span>{wordPieceParsing[wordPieceAtt]}</span></div>
                    )
                  }))
                  let linebr = <hr/>
                  b.unshift(linebr)
                  return b
                }))
                let lang = (
                  <div className={'wordattandval ' + fontSize} key={`${index}${morphoCodeLanguage}`}><span className='wordatt'>{capitalize('Language')}</span><span>: </span><span>{morphoCodeLanguage}</span></div>
                )
                a.unshift(lang)
                return a
                
              }
              else return (
                <div className={'wordattandval ' + fontSize} key={index}><span className='wordatt'>{capitalize(att)}</span><span>: </span><span>{word_obj[att]}</span></div>
              )
            })}
          </div>
        )
      },
      renderADoc = (language, docContent) => {
        let a = false;
        if (docContent) {
          a = docContent.map((row, index) => {
            if (row['block']) {
              return (
                <div className="informational" key={index}>{row['block'].replace("```", "")}</div>
              )
            }
            else {
              let wordSet = row['words']
              return (
                <div className={'storyline ' + language} key={index}>
                <div className='verseNumber'>{row['verse']}</div>
                  <div className={'story_original'}>
                    {
                      wordSet.map((ind_word, wordindex) => {
                        if (ind_word['word']) {
                          if ("morphology" in ind_word && ind_word['morphology'] === 'bibliacraSegment') {
                            // detect if right joined, left joined, or both joined
                            return (<span className={'storyword ' + ind_word['lemma'] + " " + fontSize}>{ind_word['word']}</span>)
                          }
                          return (
                            <Popover boundary={document.querySelector('#readingpassagecontainer')} key={index.toString() + wordindex.toString()} trigger="hover" variant="responsive" >
                              {/* {({ isOpen, onClose }) => ( */}
                                <>
                                  <PopoverTrigger>
                                    <span className={'storyword ' + fontSize}>{ind_word['word']}</span>
                                  </PopoverTrigger>
                                  <PopoverContent className={'bibsacpopup' + (darkMode ? ' darkmode' : '')} boxShadow={(darkMode ? "0px 0px 13px 6px rgb(0 0 0 / 60%)" : "0px 0px 13px 6px rgba(120,130,144,1)")}>
                                    <PopoverArrow bg={(darkMode ? "#606060" : '')} />
                                    <PopoverBody className="bibsacpopupcontent" maxHeight={'300px'} >
                                      {getWordAtts(language, ind_word)}
                                    </PopoverBody>
                                  </PopoverContent>
                                </>
                              {/* )} */}
                            </Popover>
                          )
                        }
                        else if (ind_word['punctuation']) {
                          if (ind_word['punctuation'] === "“") {
                            if (wordindex === 0) {
                              return (
                                <span className='rfpunctfirst' key={index.toString() + wordindex.toString()}>{ind_word['punctuation']}</span>
                              )
                            }
                            else {
                              return (
                                <span className='rfpunct' key={index.toString() + wordindex.toString()}>{ind_word['punctuation']}</span>
                              )
                            }
                          }
                          return (
                            <span key={index.toString() + wordindex.toString()}>{ind_word['punctuation']}</span>
                          )
                        }
                        return false
                      })
                    }
                  </div>
                  <div className='story_translation'>{row['translation']}</div>
                </div>
              )
            }
          })
          Promise.all(a).then(() => {
          })
        }
        return (
          <>
            {
              docContent.length > 0 &&
              <div>
                {
                  a &&
                  a
                }
              </div>
            }
          </>
        )
      },
      toggleSubscriptionType = async(type) => {

        await updateDoc(doc(fbfsdb, `users/${user?.uid}`), {
          subscriptionType: type
        });
        setSubscriptionType(type)
      }

    
  return (
    <div id='dailyreader'>
      {
        (loading || gettingData) &&
        <div id='dailyloader'>
          <Spinner size='xl' />
        </div>
      }
      {
        !loading &&
        !gettingData &&
        user &&
        subscriptionType &&
        <div id='readingpassagecontainer'>
          {
            dataToRead &&
            dataToRead.map((passage) => {
              return (
                <div>
                  <div className='passageref'>{passage.passage}</div>
                  <div className='passagedata'>
                    {
                      renderADoc(passage.language, passage.data)
                    }
                  </div>
                  <hr className='passagedivider' />
                </div>
              )
            })
          }
        </div>
      }
      {
        !loading &&
        !gettingData &&
        user &&
        !subscriptionType &&
        <div id='subscribetoreaddaily'>
          <h1>To see the daily readings, select a subscription:</h1>
          <div id='subscribedailyoptions'>
            <button className={(darkMode ? ' darkmode' : '')} onClick={((e) => {toggleSubscriptionType('standard')})}>Standard (1 Chapter of Hebrew and Greek, daily)</button>
            <button className={(darkMode ? ' darkmode' : '')} onClick={((e) => {toggleSubscriptionType('oneYear')})}>1 Year Plan (read it all in 1 year)</button>
          </div>
        </div>
      }
      {
        !loading &&
        !user &&
        <div id='signupdaily'>
          <h1>Sign in to subscribe to a daily reading plan</h1>
          <LoginOptions />
          {/* show modal to sign user in. */}
        </div>
      }
    </div>
  )
}

export default Daily