import { useState } from 'react';
import { IoIosSearch, IoMdClose } from 'react-icons/io'
import Search from '../Search/Search';

function DocsList(props) {
    const [fullscreen, setFullScreen] = useState('');
    const [showClearSearch, setShowClearSearch] = useState('hidden');
    const [q, setQ] = useState('');

    const search = (items) => {
        let a = items.filter((item, index) => {
            if (item.toLocaleLowerCase().indexOf(q.toLocaleLowerCase()) > -1) {
              return items[index]
            }
            else {
              return false
            }
          });
        return a
      };
    const changedict = (e, doc) => {
        props.setDocIsSelected(true)
        props.setHighlights(false)
        props.selectDoc(doc)
    }
    const bibsacsearch = async (event) => {
        event.preventDefault();
        blurAll();
        setFullScreen('fullscreen');
    }

    const blurAll = () => {
        var tmp = document.createElement("input");
        document.body.appendChild(tmp);
        tmp.focus();
        document.body.removeChild(tmp);
    }

    return (
        <div id='documentsearchcontainer' className={props.docIsSelected + (props.darkMode ? ' darkmode' : '')}>
            {fullscreen && <Search setHighlights={props.setHighlights} changedict={changedict} fullscreen={fullscreen} setFullScreen={setFullScreen} searchTerm={q}  setChapterToSee={props.setChapterToSee} setDocToSee={props.setDocToSee} darkMode={props.darkMode}/>}
            <form id='bibsac-search-box' onSubmit={bibsacsearch}>
                {/* search box here will query algolia */}
                {/* algolia will respond and we'll highlight responses manually */}
                <input 
                    id='bibsac-search-input'
                    placeholder='Search'
                    autoComplete="off"
                    className={(props.darkMode ? 'darkmode' : '')}
                    onChange={function(e) {
                        if (e.target.value === "") {
                          setShowClearSearch('hidden')
                        }
                        else {
                          setShowClearSearch('')
                        }
                        // set q
                        setQ(e.target.value);
                      }}
                />
                <IoMdClose
                    onClick={function(e) {
                        setShowClearSearch('hidden')
                        document.querySelector("#bibsac-search-input").value = ''
                        setQ('');
                    }}
                    id='clearsearchinput'
                    className={showClearSearch}
                />
                <IoIosSearch id='bibsac-search-icon'/>
            </form>
            <div id='bibsac-search-bottom' className={(props.darkMode ? ' darkmode' : '')}></div>
            <div id='documentscontainer'>
                <div id='documentslist'>
                {
                    search(props.documentList).map((doc, index) => {
                        return(
                            <div onClick={((e) => changedict(e, doc))} className={"ind_doc" + (props.chosenDoc === doc ? ' selected': "") + (` ${props.fontSize}`)} key={index}>{doc.replaceAll("_", " ")}</div>
                        )  
                    }
                )}
                {
                    q !== '' &&
                    <div className={'ind_doc ' + props.fontSize} onClick={bibsacsearch}>Search Bibliacra for this term.</div>
                }
                </div>
            </div>
        </div>
    )
}

export default DocsList