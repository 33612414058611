import MainLogo from "../Logo/main_logo";
import NavBar from "../Navigation/NavBar";

function Header({setFontSize, fontSize, darkMode, setDarkMode, subscriptionType, setSubscriptionType}) {
    return (
        <div id="header-container">
            <MainLogo/>
            <div>
                <NavBar setFontSize={setFontSize} fontSize={fontSize} darkMode={darkMode} setDarkMode={setDarkMode} subscriptionType={subscriptionType} setSubscriptionType={setSubscriptionType} />
            </div>
        </div>
    );
}

export default Header;