import React from 'react'
import { Helmet } from 'react-helmet';

function Privacy({fontSize}) {
  return (
    <div id='welcomedocumentpage'>
      <Helmet>
        <title>{`Bibliacra Privacy Policy`}</title>
      </Helmet>
      <div>
      <div className={'welcome ' + fontSize}>Bibliacra Privacy Policy</div>
      <div className={'welcomeabout'}>
        <p className={'aboutline ' + fontSize}>Effective Date: 28 February 2023</p>
        <p className={'aboutline ' + fontSize}>Bibliacra ("we," "us," or "our") provides users with access to the Hebrew Bible and Greek New Testament, as well as daily Greek and Hebrew exercises through our mobile application (the "App"). We are committed to protecting the privacy and security of our users' personal information.</p>
        <p className={'aboutline ' + fontSize}>This Privacy Policy outlines the types of personal information we collect, how we use and protect that information, and your rights with respect to your personal information.</p>
        <ol>
            <li>
                <span className={'aboutline ' + fontSize}>Information We Collect</span>
                <p className={'aboutline ' + fontSize}>When you use our App, we may collect the following types of personal information:</p>
                <ul>
                    <li>
                        <p className={'aboutline ' + fontSize}>Personal information you provide: When you sign up for our daily Greek and Hebrew exercises, we collect your email address and name. We use this information solely to send you daily exercises and do not share it with any third parties.</p>
                    </li>
                    <li>
                        <p className={'aboutline ' + fontSize}>Information collected automatically: We may automatically collect certain information when you use the App, such as your device type, operating system version, IP address, and mobile network information. We use this information to improve the App and user experience, and do not share it with any third parties.</p>
                    </li>
                </ul>
            </li>
            <li>
                <span className={'aboutline ' + fontSize}>How We Use Your Information</span>
                <p className={'aboutline ' + fontSize}>We use the personal information we collect to provide you with our daily Greek and Hebrew exercises and to improve the App and user experience. We do not share your personal information with any third parties.</p>
            </li>
            <li>
                <span className={'aboutline ' + fontSize}>Security of Your Information</span>
                <p className={'aboutline ' + fontSize}>We take appropriate security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. We use industry-standard security technologies and procedures to safeguard your personal information.</p>
            </li>
            <li>
                <span className={'aboutline ' + fontSize}>Your Rights</span>
                <p className={'aboutline ' + fontSize}>You have the right to request access to, correction of, or deletion of your personal information. You may also request that we restrict or object to our processing of your personal information. You may exercise these rights by contacting us at <a href='mailto:an.linguist@gmail.com' className='link'>an.linguist@gmail.com</a>. We will respond to your request within 30 days.</p>
            </li>
            <li>
                <span className={'aboutline ' + fontSize}>Changes to This Privacy Policy</span>
                <p className={'aboutline ' + fontSize}>We may update this Privacy Policy from time to time. If we make any material changes to this Privacy Policy, we will notify you by email (sent to the email address specified in your account) or by means of a notice on the App prior to the change becoming effective. We encourage you to review this Privacy Policy periodically to stay informed about our information practices and the ways you can help protect your privacy.</p>
            </li>
            <li>
                <span className={'aboutline ' + fontSize}>Contact Us</span>
                <p className={'aboutline ' + fontSize}>If you have any questions about this Privacy Policy, please contact us at <a href='mailto:an.linguist@gmail.com' className='link'>an.linguist@gmail.com</a>.</p>
            </li>
        </ol>
        <p className={'aboutline ' + fontSize}>Thank you for using Bibliacra. We are committed to protecting your privacy and security.</p>
      </div>
      </div>
      <div>
        <hr className='divider'></hr>
        <div className={'designedby ' + fontSize}>Bibliacra was designed and developed by Andrew Nelson.</div>
      </div>
    </div>
  )
}

export default Privacy