import React from 'react'
import { Helmet } from 'react-helmet'

function Home({fontSize}) {
  return (
    <div id='welcomedocumentpage'>
      <Helmet>
        <title>{`Bibliacra`}</title>
      </Helmet>
      <div>
      <div className={'welcome ' + fontSize}>Welcome!</div>
      <div className={'welcomeabout'}>
        <p className={'aboutline ' + fontSize}>Welcome to Bibliacra!</p>
        <p className={'aboutline ' + fontSize}>We're here to help you read the Hebrew Bible and Greek New Testament.</p>
        <p className={'aboutline ' + fontSize}>As you read, you can find helpful attributes associated to each word, such as a gloss, lemma, or morphological parsing by simply hovering (on desktop) or clicking (on mobile) the word.</p>
        <p className={'aboutline ' + fontSize}>All Hebrew data comes from <a className='link' href="https://github.com/openscriptures/morphhb">https://github.com/openscriptures/morphhb</a>.</p>
        <p className={'aboutline ' + fontSize}>All Greek data comes from <a className='link' href="https://github.com/eliranwong/OpenGNT">https://github.com/eliranwong/OpenGNT</a>.</p>
        {/* <p className={'aboutline ' + fontSize}>You can also create your own attributes or create notes about verses by signing in.</p> */}
        <p className={'aboutline ' + fontSize}>This website is still a work in progress. If you have any comments or feedback about the website, please <a className='link' href="mailto:an.linguist@gmail.com">reach out to me</a>.</p>
      </div>
      </div>
      <div>
        <hr className='divider'></hr>
        <div className={'designedby ' + fontSize}>Bibliacra was designed and developed by Andrew Nelson.</div>
      </div>
    </div>
  )
}

export default Home