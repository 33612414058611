import {
  Spinner,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow
} from '@chakra-ui/react';
import { IoIosArrowRoundBack } from 'react-icons/io'
// import { useDisclosure } from '@chakra-ui/react'
// import {
//   Modal,
//   ModalOverlay,
//   ModalContent,
//   ModalHeader,
//   ModalBody,
//   ModalCloseButton,
//   Button
// } from '@chakra-ui/react'
import { useEffect } from 'react';
// import { doc, updateDoc} from "firebase/firestore";
// import { fbfsdb } from '../../services/firebase';
// import NewAtt from './NewAtt';
// import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import Select from 'react-select';


// need to destructure props
function ChosenDoc({isAdmin, user, highlights, docIsSelected, setHighlights, getData, selectDoc, chosenDoc, docContent, docLoading, storyStatus, setAudioVisible, language, chapterToSee, setChapterToSee, availableChapters, setDocIsSelected, darkMode, fontSize, setDocLoading}) {
  // const { isOpen, onOpen, onClose } = useDisclosure();
  // const [editWordDetails, setEditWordDetails] = useState(null);
  // const [sentenceIndex, setSentenceIndex] = useState(null);
  // const [wordIndex, setWordIndex] = useState(null);
  // const [editingCurrentWordObj, setEditingCurrentWordObj] = useState(null);
  // const [editWordLoading, setEditWordLoading] = useState(false);
  // const [newAttributes, setNewAttributes] = useState([]);
  // const [audioUrl, setAudioUrl] = useState(null);

  // const deleteAtt = (att) => {
  //   const newItems = {...editingCurrentWordObj};
  //   delete newItems[att];
  //   setEditingCurrentWordObj(newItems);
  // },
    // addAnAttribute = () => {
    //   // first, check that last attribute is completed
    //   if (newAttributes.length > 0) {
    //     let lastEl = newAttributes[newAttributes.length - 1]
    //     if (lastEl.attributeName.trim().length === 0) {
    //       return
    //     }
    //   }
    //   setNewAttributes(newAttributes => [...newAttributes, {attributeName: '', attributeValue: ''}])
    // },
    const capitalize = (s) => {
      if (typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1)
    },
    // closeModal = async(e) => {
    //   e.preventDefault();
    //   console.log(editingCurrentWordObj)
    //   // change 'Confirm changes' button to loader
    //   setEditWordLoading(true);

    //   // send editingCurrentWordObj to server at index (user user as uid header)
    //   let updatedDocContent = docContent
    //   updatedDocContent[sentenceIndex]['words'][wordIndex] = editingCurrentWordObj

    //   let updatedDoc = {
    //     item: {
    //       data: updatedDocContent
    //     }
    //   }

    //   let source = 'documents'

    //   // update story at sentenceIndex + wordIndex
    //   await updateDoc(doc(fbfsdb, `${source}/${chosenDoc}`), updatedDoc)

    //   // change loader to 'Confirm changes'
    //   setEditWordLoading(false);
    //   onClose();
    // },
    // editWord = (word_obj, sindex, windex) => {
    //   setEditWordDetails(word_obj);
    //   setEditingCurrentWordObj(word_obj)
    //   setSentenceIndex(sindex);
    //   setWordIndex(windex);
    //   setNewAttributes([]);
    //   onOpen();
    // },
    // editWordAtts = (word_obj) => {
    //   let word_obj_keys = Object.keys(word_obj)
    //   let priority = [
    //     'word',
    //     'morphology',
    //     'gloss',
    //     'notes'
    //   ]
    //   let sorted_word_obj_keys = word_obj_keys.sort(function(a, b) {
    //     let orderA = priority.indexOf(a);
    //     let orderB = priority.indexOf(b);
    //     if (orderA !== -1 && orderB !== -1) {
    //       return orderA - orderB;
    //     } else if (orderA !== -1) {
    //       return -1;
    //     } else if (orderB !== -1) {
    //       return 1;
    //     } else {
    //       return a.localeCompare(b);
    //     }
    //   });

    //   return (
    //     <div>
    //       {sorted_word_obj_keys.map((att, index) => {
    //         if (att.startsWith("variant")) {
    //           return false
    //         }
    //         if (att === 'word') {
    //           return (
    //             <label key={`editAtt${index}`}>
    //               <div className='editAttLabel'>{capitalize(att)}</div>
    //               <textarea disabled value={word_obj[att]} ></textarea>
    //             </label>
    //           )
    //         }
    //         return (
    //           <label key={`editAtt${index}`}>
    //             <div className='editAttLabelContainer'>
    //               <div className='editAttLabel'>{capitalize(att)}</div>
    //               <div className='editAttDelete' onClick={((e) => {
    //                 // remove att element
    //                 e.target.parentNode.parentNode.parentNode.removeChild(e.target.parentNode.parentNode)
    //                 // delete the current att
    //                 deleteAtt(att);
    //               })}>Delete</div>
    //             </div>
    //             <textarea defaultValue={word_obj[att]} onChange={((e) => setTimeout(() => setEditingCurrentWordObj({
    //               ...editingCurrentWordObj,
    //               [att]: e.target.value
    //             }), 5))}></textarea>
    //           </label>
    //         )
    //       })}
    //     </div>
    //   )
    // },
    getWordAtts = (word_obj) => {
      let word_obj_keys = Object.keys(word_obj)
      let priority = [
        'morphology',
        'gloss',
        'notes'
      ]
      let sorted_word_obj_keys = word_obj_keys.sort(function(a, b) {
        let orderA = priority.indexOf(a);
        let orderB = priority.indexOf(b);
        if (orderA !== -1 && orderB !== -1) {
          return orderA - orderB;
        } else if (orderA !== -1) {
          return -1;
        } else if (orderB !== -1) {
          return 1;
        } else {
          return a.localeCompare(b);
        }
      });

      const getGender = (val) => {
        switch (val) {
          case 'b':
            return 'Both'
          case 'c':
            return 'Common'
          case 'f':
            return 'Feminine'
          case 'm':
            return 'Masculine'
          default:
            break;
        }
      }

      const getNumber = (val) => {
        switch (val) {
          case 'd':
            return 'Dual'
          case 'p':
            return 'Plural'
          case 's':
            return 'Singular'
          default:
            break;
        }
      }
      const getState = (val) => {
        switch (val) {
          case 'a':
            return 'Absolute'
          case 'c':
            return 'Construct'
          case 'd':
            return 'Determined'
          default:
            break;
        }
      };
      const getAdjectiveType = (val) => {
        switch (val) {
          case 'a':
            return 'Adjective'
          case 'c':
            return 'Cardinal Number'
          case 'g':
            return 'Gentilic'
          case 'o':
            return 'Ordinal Number'
          default:
            break;
        }
      }
      const getNounType = (val) => {
        switch (val) {
          case 'c':
            return 'Common'
          case 'g':
            return 'Gentilic'
          case 'p':
            return 'Proper Noun'
          default:
            break;
        }
      }
      const getPronounType = (val) => {
        switch (val) {
          case 'd':
            return 'Demonstrative'
          case 'f':
            return 'Indefinite'
          case 'i':
            return 'Interrogative'
          case 'p':
            return 'Personal'
          case 'r':
            return 'Relative'
          default:
            break;
        }
      }

      return (
        <div>
          {sorted_word_obj_keys.map((att, index) => {
            if (att.startsWith("variant") || att === "word") {
              return false
            }
            else if (att === "morphology" && language === 'hebrew') {
              // parse out hebrew morphology, e.g. HTd/Ncbsa and  HR/Ncfsa
              let morphoCode = word_obj[att]
              let morphoCodeLanguage = morphoCode[0] === "H" ? 'Hebrew' : 'Aramaic';
              morphoCode = morphoCode.slice(1);

              let morphoCodeArr = morphoCode.split('/');
              let parsedMorphology = []

              // loop through split morpho code,
              for (let i = 0; i < morphoCodeArr.length; i++) {
                let currentPartMorph = morphoCodeArr[i]
                let parsedMorph = {}

                let POS = currentPartMorph[0]
                switch (POS) {
                  case 'V':
                    parsedMorph['Part of Speech'] = 'Verb';
                    let vStem = currentPartMorph[1]
                    if (morphoCodeLanguage === 'Hebrew') {
                      switch (vStem) {
                        case 'q':
                          parsedMorph['Verb Stem'] = 'Qal'
                          break;
                        case 'N':
                          parsedMorph['Verb Stem'] = 'Niphal'
                          break;
                        case 'p':
                          parsedMorph['Verb Stem'] = 'Piel'
                          break;
                        case 'P':
                          parsedMorph['Verb Stem'] = 'Pual'
                          break;
                        case 'h':
                          parsedMorph['Verb Stem'] = 'Hiphil'
                          break;
                        case 'H':
                          parsedMorph['Verb Stem'] = 'Hophal'
                          break;
                        case 't':
                          parsedMorph['Verb Stem'] = 'Hithpael'
                          break;
                        case 'o':
                          parsedMorph['Verb Stem'] = 'Polel'
                          break;
                        case 'O':
                          parsedMorph['Verb Stem'] = 'Polal'
                          break;
                        case 'r':
                          parsedMorph['Verb Stem'] = 'Hithpolel'
                          break;
                        case 'm':
                          parsedMorph['Verb Stem'] = 'Poel'
                          break;
                        case 'M':
                          parsedMorph['Verb Stem'] = 'Poal'
                          break;
                        case 'k':
                          parsedMorph['Verb Stem'] = 'Palel'
                          break;
                        case 'K':
                          parsedMorph['Verb Stem'] = 'Pulal'
                          break;
                        case 'Q':
                          parsedMorph['Verb Stem'] = 'Qal Passive'
                          break;
                        case 'l':
                          parsedMorph['Verb Stem'] = 'Pilpel'
                          break;
                        case 'L':
                          parsedMorph['Verb Stem'] = 'Polpal'
                          break;
                        case 'f':
                          parsedMorph['Verb Stem'] = 'Hithpalpel'
                          break;
                        case 'D':
                          parsedMorph['Verb Stem'] = 'Nithpael'
                          break;
                        case 'j':
                          parsedMorph['Verb Stem'] = 'Pealal'
                          break;
                        case 'i':
                          parsedMorph['Verb Stem'] = 'Pilel'
                          break;
                        case 'u':
                          parsedMorph['Verb Stem'] = 'Hothpaal'
                          break;
                        case 'c':
                          parsedMorph['Verb Stem'] = 'Tiphil'
                          break;
                        case 'v':
                          parsedMorph['Verb Stem'] = 'Hishtaphel'
                          break;
                        case 'w':
                          parsedMorph['Verb Stem'] = 'Nithpalel'
                          break;
                        case 'y':
                          parsedMorph['Verb Stem'] = 'Nithpoel'
                          break;
                        case 'z':
                          parsedMorph['Verb Stem'] = 'Hithpoel'
                          break;
                        default:
                          break;
                      }
                    }
                    else if (morphoCodeLanguage === 'Aramaic') {
                      switch (vStem) {
                        case 'q':
                          parsedMorph['Verb Stem'] = 'Peal'
                          break;
                        case 'Q':
                          parsedMorph['Verb Stem'] = 'Peil'
                          break;
                        case 'u':
                          parsedMorph['Verb Stem'] = 'Hithpeel'
                          break;
                        case 'p':
                          parsedMorph['Verb Stem'] = 'Pael'
                          break;
                        case 'P':
                          parsedMorph['Verb Stem'] = 'Ithpaal'
                          break;
                        case 'M':
                          parsedMorph['Verb Stem'] = 'Hithpaal'
                          break;
                        case 'a':
                          parsedMorph['Verb Stem'] = 'Aphel'
                          break;
                        case 'h':
                          parsedMorph['Verb Stem'] = 'Haphel'
                          break;
                        case 's':
                          parsedMorph['Verb Stem'] = 'Saphel'
                          break;
                        case 'e':
                          parsedMorph['Verb Stem'] = 'Shaphel'
                          break;
                        case 'H':
                          parsedMorph['Verb Stem'] = 'Hophal'
                          break;
                        case 'i':
                          parsedMorph['Verb Stem'] = 'Ithpeel'
                          break;
                        case 't':
                          parsedMorph['Verb Stem'] = 'Hishtaphel'
                          break;
                        case 'v':
                          parsedMorph['Verb Stem'] = 'Ishtaphel'
                          break;
                        case 'w':
                          parsedMorph['Verb Stem'] = 'Hithaphel'
                          break;
                        case 'o':
                          parsedMorph['Verb Stem'] = 'Polel'
                          break;
                        case 'z':
                          parsedMorph['Verb Stem'] = 'Ithpoel'
                          break;
                        case 'r':
                          parsedMorph['Verb Stem'] = 'Hithpolel'
                          break;
                        case 'f':
                          parsedMorph['Verb Stem'] = 'Hithpalpel'
                          break;
                        case 'b':
                          parsedMorph['Verb Stem'] = 'Hephal'
                          break;
                        case 'c':
                          parsedMorph['Verb Stem'] = 'Tiphel'
                          break;
                        case 'm':
                          parsedMorph['Verb Stem'] = 'Poel'
                          break;
                        case 'l':
                          parsedMorph['Verb Stem'] = 'Palpel'
                          break;
                        case 'L':
                          parsedMorph['Verb Stem'] = 'Ithpalpel'
                          break;
                        case 'O':
                          parsedMorph['Verb Stem'] = 'Ithpolel'
                          break;
                        case 'G':
                          parsedMorph['Verb Stem'] = 'Ittaphal'
                          break;
                        default:
                          break;
                      }
                    }
                    let vConjType = currentPartMorph[2]
                    switch (vConjType) {
                      case 'a':
                        parsedMorph["Verb Conjugation Type"] = 'Infinitive Absolute'
                        break;
                      case 'c':
                        parsedMorph["Verb Conjugation Type"] = 'Infinitive Construct'
                        break;
                      case 'p':
                        parsedMorph["Verb Conjugation Type"] = 'Perfect'
                        if (currentPartMorph[3] && currentPartMorph[3] !== 'x') parsedMorph["Person"] = currentPartMorph[3];
                        if (currentPartMorph[4] && currentPartMorph[4] !== 'x') parsedMorph["Gender"] = getGender(currentPartMorph[4]);
                        if (currentPartMorph[5] && currentPartMorph[5] !== 'x') parsedMorph["Number"] = getNumber(currentPartMorph[5]);
                        break;
                      case 'q':
                        parsedMorph["Verb Conjugation Type"] = 'Sequential Perfect'
                        if (currentPartMorph[3] && currentPartMorph[3] !== 'x') parsedMorph["Person"] = currentPartMorph[3];
                        if (currentPartMorph[4] && currentPartMorph[4] !== 'x') parsedMorph["Gender"] = getGender(currentPartMorph[4]);
                        if (currentPartMorph[5] && currentPartMorph[5] !== 'x') parsedMorph["Number"] = getNumber(currentPartMorph[5]);
                        break;
                      case 'i':
                        parsedMorph["Verb Conjugation Type"] = 'Imperfect'
                        if (currentPartMorph[3] && currentPartMorph[3] !== 'x') parsedMorph["Person"] = currentPartMorph[3];
                        if (currentPartMorph[4] && currentPartMorph[4] !== 'x') parsedMorph["Gender"] = getGender(currentPartMorph[4]);
                        if (currentPartMorph[5] && currentPartMorph[5] !== 'x') parsedMorph["Number"] = getNumber(currentPartMorph[5]);
                        break;
                      case 'w':
                        parsedMorph["Verb Conjugation Type"] = 'Sequential Imperfect'
                        if (currentPartMorph[3] && currentPartMorph[3] !== 'x') parsedMorph["Person"] = currentPartMorph[3];
                        if (currentPartMorph[4] && currentPartMorph[4] !== 'x') parsedMorph["Gender"] = getGender(currentPartMorph[4]);
                        if (currentPartMorph[5] && currentPartMorph[5] !== 'x') parsedMorph["Number"] = getNumber(currentPartMorph[5]);
                        break;
                      case 'h':
                        parsedMorph["Verb Conjugation Type"] = 'Cohortative'
                        if (currentPartMorph[3] && currentPartMorph[3] !== 'x') parsedMorph["Person"] = currentPartMorph[3];
                        if (currentPartMorph[4] && currentPartMorph[4] !== 'x') parsedMorph["Gender"] = getGender(currentPartMorph[4]);
                        if (currentPartMorph[5] && currentPartMorph[5] !== 'x') parsedMorph["Number"] = getNumber(currentPartMorph[5]);
                        break;
                      case 'j':
                        parsedMorph["Verb Conjugation Type"] = 'Jussive'
                        if (currentPartMorph[3] && currentPartMorph[3] !== 'x') parsedMorph["Person"] = currentPartMorph[3];
                        if (currentPartMorph[4] && currentPartMorph[4] !== 'x') parsedMorph["Gender"] = getGender(currentPartMorph[4]);
                        if (currentPartMorph[5] && currentPartMorph[5] !== 'x') parsedMorph["Number"] = getNumber(currentPartMorph[5]);
                        break;
                      case 'r':
                        parsedMorph["Verb Conjugation Type"] = 'Participle Active'
                        if (currentPartMorph[3] && currentPartMorph[3] !== 'x') parsedMorph["Gender"] = getGender(currentPartMorph[4]);
                        if (currentPartMorph[4] && currentPartMorph[4] !== 'x') parsedMorph["Number"] = getNumber(currentPartMorph[4]);
                        if (currentPartMorph[5] && currentPartMorph[5] !== 'x') parsedMorph["State"] = getState(currentPartMorph[5]);
                        break;
                      case 's':
                        parsedMorph["Verb Conjugation Type"] = 'Participle Passive'
                        if (currentPartMorph[3] && currentPartMorph[3] !== 'x') parsedMorph["Gender"] = getGender(currentPartMorph[4]);
                        if (currentPartMorph[4] && currentPartMorph[4] !== 'x') parsedMorph["Number"] = getNumber(currentPartMorph[4]);
                        if (currentPartMorph[5] && currentPartMorph[5] !== 'x') parsedMorph["State"] = getState(currentPartMorph[5]);
                        break;
                      default:
                        break;
                    }
                    break;
                  case 'A':
                    // takes type, gender, number, and state next four values)
                    parsedMorph['Part of Speech'] = 'Adjective';
                    if (currentPartMorph[1] && currentPartMorph[1] !== 'x') parsedMorph["Adjective Type"] = getAdjectiveType(currentPartMorph[1]);
                    if (currentPartMorph[2] && currentPartMorph[2] !== 'x') parsedMorph["Gender"] = getGender(currentPartMorph[2]);
                    if (currentPartMorph[3] && currentPartMorph[3] !== 'x') parsedMorph["Number"] = getNumber(currentPartMorph[3]);
                    if (currentPartMorph[4] && currentPartMorph[4] !== 'x') parsedMorph["State"] = getState(currentPartMorph[4]);
                    break;
                  case 'C':
                    parsedMorph['Part of Speech'] = 'Conjunction';
                    break;
                  case 'D':
                    parsedMorph['Part of Speech'] = 'Adverb';
                    break;
                  case 'N':
                    // get noun type (second letter)
                    parsedMorph['Part of Speech'] = 'Noun';
                    if (currentPartMorph[1] !== 'x') parsedMorph['Noun Type'] = getNounType(currentPartMorph[1]);
                    if (currentPartMorph[1] !== 'p') {
                      if (currentPartMorph[2] && currentPartMorph[2] !== 'x') parsedMorph["Gender"] = getGender(currentPartMorph[2]);
                      if (currentPartMorph[3] && currentPartMorph[3] !== 'x') parsedMorph["Number"] = getNumber(currentPartMorph[3]);
                      if (currentPartMorph[4] && currentPartMorph[4] !== 'x') parsedMorph["State"] = getState(currentPartMorph[4]);
                    }
                    break;
                  case 'P':
                    parsedMorph['Part of Speech'] = 'Pronoun';
                    // get type, person, gender, number (next 4 values)
                    if (currentPartMorph[1] && currentPartMorph[1] !== 'x') parsedMorph['Pronoun Type'] = getPronounType(currentPartMorph[1]);
                    if (currentPartMorph[2] && currentPartMorph[2] !== 'x') parsedMorph["Person"] = currentPartMorph[2];
                    if (currentPartMorph[3] && currentPartMorph[3] !== 'x') parsedMorph["Gender"] = getGender(currentPartMorph[3]);
                    if (currentPartMorph[4] && currentPartMorph[4] !== 'x') parsedMorph["Number"] = getNumber(currentPartMorph[4]);
                    break;
                  case 'R':
                    parsedMorph['Part of Speech'] = 'Preposition'
                    if (currentPartMorph[1] && currentPartMorph[1] !== 'x') parsedMorph['Definite Article'] = 'Attached'
                    break;
                  case 'S':
                    parsedMorph['Part of Speech'] = 'Suffix'
                    // get type
                    let suffixType = currentPartMorph[1]
                    switch (suffixType) {
                      case 'd':
                        parsedMorph['Suffix Type'] = 'Directional He'
                        break;
                      case 'h':
                        parsedMorph['Suffix Type'] = 'Paragogic He'
                        break;
                      case 'n':
                        parsedMorph['Suffix Type'] = 'Paragogic Nun'
                        break;
                      case 'p':
                        parsedMorph['Suffix Type'] = 'Pronominal'
                        if (currentPartMorph[2] && currentPartMorph[2] !== 'x') parsedMorph["Person"] = currentPartMorph[2];
                        if (currentPartMorph[3] && currentPartMorph[3] !== 'x') parsedMorph["Gender"] = getGender(currentPartMorph[3]);
                        if (currentPartMorph[4] && currentPartMorph[4] !== 'x') parsedMorph["Number"] = getNumber(currentPartMorph[4]);
                        break;
                      default:
                        break;
                    }
                    break;
                  case 'T':
                    parsedMorph['Part of Speech'] = 'Particle'
                    let particleType = currentPartMorph[1]
                    switch (particleType) {
                      case 'a':
                        parsedMorph['Particle Type'] = 'Affirmation'
                          break;
                      case 'd':
                        parsedMorph['Particle Type'] = 'Definite Article'
                          break;
                      case 'e':
                        parsedMorph['Particle Type'] = 'Exhortation'
                        break;
                      case 'i':
                        parsedMorph['Particle Type'] = 'Interrogative'
                        break;
                      case 'j':
                        parsedMorph['Particle Type'] = 'Interjection'
                        break;
                      case 'm':
                        parsedMorph['Particle Type'] = 'Demonstrative'
                        break;
                      case 'n':
                        parsedMorph['Particle Type'] = 'Negative'
                        break;
                      case 'o':
                        parsedMorph['Particle Type'] = 'Direct Object Marker'
                        break;
                      case 'r':
                        parsedMorph['Particle Type'] = 'Relative'
                        break;
                      default:
                        break;
                    }
                    break;
                  default:
                    break;
                }
                parsedMorphology.push(parsedMorph)
              }
              
              let a = (parsedMorphology.map((wordPieceParsing, wordPieceIndex) => {
                let b = (Object.keys(wordPieceParsing).map((wordPieceAtt, wordPieceAttIndex) => {
                  return (
                    <div className={'wordattandval ' + fontSize} key={`${index}${wordPieceIndex}${wordPieceAttIndex}`}><span className='wordatt'>{capitalize(wordPieceAtt)}</span><span>: </span><span>{wordPieceParsing[wordPieceAtt]}</span></div>
                  )
                }))
                let linebr = <hr/>
                b.unshift(linebr)
                return b
              }))
              let lang = (
                <div className={'wordattandval ' + fontSize} key={`${index}${morphoCodeLanguage}`}><span className='wordatt'>{capitalize('Language')}</span><span>: </span><span>{morphoCodeLanguage}</span></div>
              )
              a.unshift(lang)
              return a
              
            }
            else return (
              <div className={'wordattandval ' + fontSize} key={index}><span className='wordatt'>{capitalize(att)}</span><span>: </span><span>{word_obj[att]}</span></div>
            )
          })}
        </div>
      )
    },
    renderADoc = () => {
      let a = false;
      if (docContent) {
        a = docContent.map((row, index) => {
          if (row['block']) {
            return (
              <div className="informational" key={index}>{row['block'].replace("```", "")}</div>
            )
          }
          else {
            let wordSet = row['words']
            return (
              <div className={'storyline ' + language} key={index}>
              <div className='verseNumber'>{row['verse']}</div>
                <div className={'story_original'}>
                  {
                    wordSet.map((ind_word, wordindex) => {
                      if (ind_word['word']) {
                        if ("morphology" in ind_word && ind_word['morphology'] === 'bibliacraSegment') {
                          // detect if right joined, left joined, or both joined
                          return (<span className={'storyword ' + ind_word['lemma'] + " " + fontSize + " " + ((JSON.parse(decodeURIComponent(highlights))[index] ? JSON.parse(decodeURIComponent(highlights))[index].includes(wordindex) : false) ? 'highlighted' : '')}>{ind_word['word']}</span>)
                        }
                        return (
                          <Popover boundary={document.querySelector('#documentContent')} key={index.toString() + wordindex.toString()} trigger="hover" variant="responsive" >
                            {/* {({ isOpen, onClose }) => ( */}
                              <>
                                <PopoverTrigger>
                                  <span className={'storyword ' + fontSize + " " + ((JSON.parse(decodeURIComponent(highlights))[index] ? JSON.parse(decodeURIComponent(highlights))[index].includes(wordindex) : false) ? 'highlighted' : '')}>{ind_word['word']}</span>
                                </PopoverTrigger>
                                <PopoverContent className={'bibsacpopup' + (darkMode ? ' darkmode' : '')} boxShadow={(darkMode ? "0px 0px 13px 6px rgb(0 0 0 / 60%)" : "0px 0px 13px 6px rgba(120,130,144,1)")}>
                                  <PopoverArrow bg={(darkMode ? "#606060" : '')} />
                                  <PopoverBody className="bibsacpopupcontent" maxHeight={'300px'} >
                                    {getWordAtts(ind_word)}
                                  </PopoverBody>
                                </PopoverContent>
                              </>
                            {/* )} */}
                          </Popover>
                        )
                      }
                      else if (ind_word['punctuation']) {
                        if (ind_word['punctuation'] === "“") {
                          if (wordindex === 0) {
                            return (
                              <span className='rfpunctfirst' key={index.toString() + wordindex.toString()}>{ind_word['punctuation']}</span>
                            )
                          }
                          else {
                            return (
                              <span className='rfpunct' key={index.toString() + wordindex.toString()}>{ind_word['punctuation']}</span>
                            )
                          }
                        }
                        return (
                          <span key={index.toString() + wordindex.toString()}>{ind_word['punctuation']}</span>
                        )
                      }
                      return false
                    })
                  }
                </div>
                <div className='story_translation'>{row['translation']}</div>
              </div>
            )
          }
        })
        Promise.all(a).then(() => {
          setDocLoading('docnotloading')
        })
      }
      return (
        <>
          {
            docContent.length > 0 &&
            <div>
              {/* {
                audioUrl &&
                <div id='storyAudio'>
                  <AudioPlayer
                    autoPlay
                    src={audioUrl}
                    onPlay={e => console.log("onPlay")}
                    // other props here
                  />
                </div>
              } */}
              {
                a &&
                a
              }
              {/* {
                isOpen &&
                <Modal isOpen={isOpen} onClose={onClose}>
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader>Edit Word</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      <form id='editWordForm' onSubmit={((e) => closeModal(e))}>
                        {editWordAtts(editWordDetails)}
                        <>{newAttributes.map((newAtt, index) => {
                          return (<NewAtt key={index} editingCurrentWordObj={editingCurrentWordObj} setEditingCurrentWordObj={setEditingCurrentWordObj} deleteAtt={deleteAtt} currentIndex={index} newAtt={newAtt} newAttributes={newAttributes} setNewAttributes={setNewAttributes}/>)
                        })}</>
                        <div id='editWordBtnsContainer'>
                          <Button backgroundColor='#788290' color={'white'} onClick={((e) => {addAnAttribute()})}>
                            Add an attribute
                          </Button>
                          <Button isLoading={editWordLoading} backgroundColor='#788290' color={'white'} type='submit'>
                            Confirm changes
                          </Button>
                        </div>
                      </form>
                    </ModalBody>
                  </ModalContent>
                </Modal>
              } */}
            </div>
          }
          {
            docContent.length === 0 &&
            <div>
              <div id='doctitle'>Uh-Oh!</div>
              <p id='docnotfound'>We couldn't find that story.</p>
            </div>
          }
        </>
      )
    }

    useEffect(() => {
      if (highlights.length > 0) {
        // set highlights as url param.
        window.history.replaceState(null, "", `/read/${encodeURI(chosenDoc)}/${encodeURI(chapterToSee)}?highlights=${highlights}`)
      }
    }, [highlights, chosenDoc, chapterToSee])
    

    // useEffect(() => {
    //   const storage = getStorage(app);
    //   console.log(`checking for audio at stories/${chosenDoc}/${chosenDoc}.wav`)
    //   const folderRef = ref(storage, `stories/${chosenDoc}`);
    //   let audioRef = false;
    //   listAll(folderRef).then((res) => {
    //     console.log(res)
    //     console.log(res.items)
    //     if (res.items.length > 0) {
    //       audioRef = ref(storage, `stories/${chosenDoc}/${chosenDoc}.wav`)
    //     }
        
    //     if (audioRef) {
    //       getDownloadURL(audioRef).then(url => {
    //         setAudioUrl(url);
    //         setAudioVisible(true);
    //       }).catch((err) => {
    //         setAudioUrl(false);
    //         setAudioVisible(false);
    //       })
    //     }
    //     else {
    //       setAudioUrl(false);
    //       setAudioVisible(false);
    //     }
    //   });      
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [chosenDoc]);

    useEffect(() => {
      if (docLoading !== 'docloading') {
        console.log(docLoading)
        document.querySelector('#documentContent').scrollTo({ top: 0, behavior: 'smooth' });;
      }
    }, [docLoading])

    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? 'white' : darkMode ? 'white' : 'black',
        backgroundColor: state.isSelected ? '#788290': darkMode ? '#303030' : 'white',
        cursor: 'pointer',
        borderRadius: '4px',
        ":hover": {
          backgroundColor: state.isSelected ? '#788290' : "#A0AEC0",
        }
        // rest of styling
      }),
      menu: (provided, state) => ({
        ...provided,
        overflow: 'hidden',
        backgroundColor: darkMode ? '#303030' : 'white'
      }),
      control: (provided, state) => ({
        ...provided,
        backgroundColor: '#788290',
        color: 'white',
        cursor: 'pointer'
      }),
      input: (provided, state) => ({
        ...provided,
        color: 'white',
        fontSize: '16px'
      }),
      singleValue: (provided, state) => ({
        ...provided,
        color: 'white'
      })
    }


  return (
    <div id='chosen-doc' className={docIsSelected + (darkMode ? ' darkmode' : '')}>
      <div id='documentloader' className={docLoading}><Spinner size='xl' /></div>
      {
        <div id='doctitle' className={docLoading}>
          <IoIosArrowRoundBack onClick={() => { setDocIsSelected("docnotselected") }} id='backtodocs' className={docIsSelected} />
          <span id='booktitle'>{chosenDoc.replaceAll("_", " ")}</span>
          {
            availableChapters && availableChapters.length > 0 &&
            <Select
              id='chapterchooser'
              name='chapterchooser'
              menuPlacement='bottom'
              maxMenuHeight={175}
              onChange={((options) => {setChapterToSee(options['value'])})} 
              value={{value: chapterToSee, label: chapterToSee}}
              options={availableChapters}
              styles={customStyles}
              />
          }
        </div>
      }
      <div id="documentContent" className={docLoading}>
        {
          renderADoc()
        }
      </div>
    </div>
  )
}

export default ChosenDoc