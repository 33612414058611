import './App.css';
import Home from './components/Home/Home';
import Stories from './components/Stories/Stories';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Header from './components/Header/Header';
import { useState, useEffect } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    useDisclosure
  } from '@chakra-ui/react';
import { auth, LoginOptions } from './services/firebase';
import { useAuthState } from "react-firebase-hooks/auth";
import Daily from './components/Daily/Daily';
import Privacy from './components/Privacy/Privacy';

function App() {
    const [user, loading] = useAuthState(auth);
    const [fontSize, setFontSize] = useState('smallmed');
    const [darkMode, setDarkMode] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [subscriptionType, setSubscriptionType] = useState(false);

  let bookData = {
    Genesis: {
        chapterCount: 50,
        language: 'hebrew'
    },
    Exodus: {
        chapterCount: 40,
        language: 'hebrew'
    },
    Leviticus: {
        chapterCount: 27,
        language: 'hebrew'
    },
    Numbers: {
        chapterCount: 36,
        language: 'hebrew'
    },
    Deuteronomy: {
        chapterCount: 34,
        language: 'hebrew'
    },
    Joshua: {
        chapterCount: 24,
        language: 'hebrew'
    },
    Judges: {
        chapterCount: 21,
        language: 'hebrew'
    },
    Ruth: {
        chapterCount: 4,
        language: 'hebrew'
    },
    I_Samuel: {
        chapterCount: 31,
        language: 'hebrew'
    },
    II_Samuel: {
        chapterCount: 24,
        language: 'hebrew'
    },
    I_Kings: {
        chapterCount: 22,
        language: 'hebrew'
    },
    II_Kings: {
        chapterCount: 25,
        language: 'hebrew'
    },
    I_Chronicles: {
        chapterCount: 29,
        language: 'hebrew'
    },
    II_Chronicles: {
        chapterCount: 36,
        language: 'hebrew'
    },
    Ezra: {
        chapterCount: 10,
        language: 'hebrew'
    },
    Nehemiah: {
        chapterCount: 13,
        language: 'hebrew'
    },
    Esther: {
        chapterCount: 10,
        language: 'hebrew'
    },
    Job: {
        chapterCount: 42,
        language: 'hebrew'
    },
    Psalms: {
        chapterCount: 150,
        language: 'hebrew'
    },
    Proverbs: {
        chapterCount: 31,
        language: 'hebrew'
    },
    Ecclesiastes: {
        chapterCount: 12,
        language: 'hebrew'
    },
    Song_of_Solomon: {
        chapterCount: 8,
        language: 'hebrew'
    },
    Isaiah: {
        chapterCount: 66,
        language: 'hebrew'
    },
    Jeremiah: {
        chapterCount: 52,
        language: 'hebrew'
    },
    Lamentations: {
        chapterCount: 5,
        language: 'hebrew'
    },
    Ezekiel: {
        chapterCount: 48,
        language: 'hebrew'
    },
    Daniel: {
        chapterCount: 12,
        language: 'hebrew'
    },
    Hosea: {
        chapterCount: 14,
        language: 'hebrew'
    },
    Joel: {
        chapterCount: 4,
        language: 'hebrew'
    },
    Amos: {
        chapterCount: 9,
        language: 'hebrew'
    },
    Obadiah: {
        chapterCount: 1,
        language: 'hebrew'
    },
    Jonah: {
        chapterCount: 4,
        language: 'hebrew'
    },
    Micah: {
        chapterCount: 7,
        language: 'hebrew'
    },
    Nahum: {
        chapterCount: 3,
        language: 'hebrew'
    },
    Habakkuk: {
        chapterCount: 3,
        language: 'hebrew'
    },
    Zephaniah: {
        chapterCount: 3,
        language: 'hebrew'
    },
    Haggai: {
        chapterCount: 2,
        language: 'hebrew'
    },
    Zechariah: {
        chapterCount: 14,
        language: 'hebrew'
    },
    Malachi: {
        chapterCount: 3,
        language: 'hebrew'
    },
    Matthew: {
      chapterCount: 28,
      language: 'greek'
    },
    Mark: {
        chapterCount: 16,
        language: 'greek'
    },
    Luke: {
        chapterCount: 24,
        language: 'greek'
    },
    John: {
        chapterCount: 21,
        language: 'greek'
    },
    Acts: {
        chapterCount: 28,
        language: 'greek'
    },
    Romans: {
        chapterCount: 16,
        language: 'greek'
    },
    "1_Corinthians": {
        chapterCount: 16,
        language: 'greek'
    },
    "2_Corinthians": {
        chapterCount: 13,
        language: 'greek'
    },
    Galatians: {
        chapterCount: 6,
        language: 'greek'
    },
    Ephesians: {
        chapterCount: 6,
        language: 'greek'
    },
    Philippians: {
      chapterCount: 4,
      language: 'greek'
    },
    Colossians: {
        chapterCount: 4,
        language: 'greek'
    },
    "1_Thessalonians": {
        chapterCount: 5,
        language: 'greek'
    },
    "2_Thessalonians": {
        chapterCount: 3,
        language: 'greek'
    },
    "1_Timothy": {
        chapterCount: 6,
        language: 'greek'
    },
    "2_Timothy": {
        chapterCount: 4,
        language: 'greek'
    },
    Titus: {
        chapterCount: 3,
        language: 'greek'
    },
    Philemon: {
        chapterCount: 1,
        language: 'greek'
    },
    Hebrews: {
        chapterCount: 13,
        language: 'greek'
    },
    James: {
        chapterCount: 5,
        language: 'greek'
    },
    "1_Peter": {
        chapterCount: 5,
        language: 'greek'
    },
    "2_Peter": {
        chapterCount: 3,
        language: 'greek'
    },
    "1_John": {
        chapterCount: 5,
        language: 'greek'
    },
    "2_John": {
        chapterCount: 1,
        language: 'greek'
    },
    "3_John": {
        chapterCount: 1,
        language: 'greek'
    },
    Jude: {
        chapterCount: 1,
        language: 'greek'
    },
    Revelation: {
        chapterCount: 22,
        language: 'greek'
    }
  }
  
  useEffect(() => {
    // check if fontSize and darkMode have been set in local storage.
    const font = localStorage.getItem('fontSize');
    const dm = localStorage.getItem('darkMode');

    if (font) {
        setFontSize(font);
    }
    if (dm) {
        if (JSON.parse(dm) === true) {
            setDarkMode(dm);
        }
    }
  }, [])



  useEffect(() => {
    if (loading) {
        return
    }
    if (user?.uid) {
        onClose()
    }
    const bibliacraOpenedLocally = localStorage.getItem('openedLocally')
    if (!bibliacraOpenedLocally) {
        onOpen();
        localStorage.setItem('openedLocally', true)
    }
  }, [loading, user, onClose, onOpen])
  
  
  return (
    <div className={"App " + (darkMode ? 'darkmode' : '')}>        
        <Router>
          <Header setFontSize={setFontSize} fontSize={fontSize} darkMode={darkMode} setDarkMode={setDarkMode} subscriptionType={subscriptionType} setSubscriptionType={setSubscriptionType}/>
          <Routes>
            <Route path="/" element={<Home fontSize={fontSize} darkMode={darkMode}/>} />
            <Route path="/read/*" element={<Stories bookData={bookData} fontSize={fontSize} darkMode={darkMode}/>} />
            <Route path="/daily" element={<Daily fontSize={fontSize} darkMode={darkMode} subscriptionType={subscriptionType} setSubscriptionType={setSubscriptionType} />} />
            <Route path="/privacy" element={<Privacy fontSize={fontSize} darkMode={darkMode} />} />
          </Routes>
        </Router>

        {isOpen &&
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent className={'settingsmodalcontent' + (darkMode ? ' darkmode' : '')}>
                <ModalHeader>Welcome!</ModalHeader>
                <ModalCloseButton />
                <ModalBody id='chakramodalwelcome'>
                    <div style={{textAlign: 'center'}}>
                        <div style={{fontSize: '20px', margin: 10 + 'px ' + 0 + 'px'}}>Thanks for checking out Bibliacra.</div>
                        <div style={{fontSize: '18px', margin: 10 + 'px ' + 0 + 'px'}}>Our mission is to help you read the Hebrew Bible and Greek New Testament.</div>
                        <div style={{fontSize: '16px', margin: 10 + 'px ' + 0 + 'px'}}>You can sign up via Google or email and you'll automatically start receiving daily readings in your inbox. You can always change or disable this feature in the Bibliacra settings.</div>
                        <LoginOptions />
                    </div>
                </ModalBody>
                </ModalContent>
            </Modal>
        }
    </div>
  );
}

export default App;
