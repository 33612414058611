import React, { useEffect, useState } from 'react'
import { IoMdClose, IoIosSearch} from 'react-icons/io'
import {
    Spinner
} from '@chakra-ui/react';
import SearchResults from './SearchResults';

function Search(props) {
    const [searchLoader, setSearchLoader] = useState('loading');
    const [results, setResults] = useState([]);
    const [showClearSearch, setShowClearSearch] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [finalResults, setFinalResults] = useState({});

    const queryBibSac = async (query) => {
        let sendObj = {}
        sendObj['q_type'] = 'simple'
        sendObj['q_word'] = query
        let postInfo = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(sendObj)
        }
        let test_admin_url = 'https://biblia-sacra-be-2q23fvkbda-uc.a.run.app/queryBibSac'
        let query_results = await fetch(test_admin_url, postInfo).then(response => response.json())
            .then(function(data) { return data })
        console.log(query_results)
        setResults(query_results)
        setSearchLoader('')
    }

    const newSearch = (e) => {
        e.preventDefault()
        setResults([])
        setSearchLoader('loading')
        queryBibSac(searchTerm)
    }

    useEffect(() => {
        document.documentElement.addEventListener("click", function(e) {
            if (!document.getElementById('documentpage').contains(e.target)) {
                props.setFullScreen()
            }
            // add event listener for clicking on result
            // this event listener should a) close the search window, b) create highlights obj,
            // c) pass highlights obj up to change doc and add highlights param to url.
        })
        document.addEventListener('keydown', (event) => {
            if (event.key === 'Escape') {
             //if esc key was not pressed in combination with ctrl or alt or shift
                const isNotCombinedKey = !(event.ctrlKey || event.altKey || event.shiftKey);
                if (isNotCombinedKey) {
                    props.setFullScreen()
                }
            }
        });
        queryBibSac(props.searchTerm)
    }, [props, finalResults])
  return (
    <div id='fullscreen' className={props.fullscreen + (props.darkMode ? ' darkmode' : '')}>
        <div id='search-content'>

        <IoMdClose onClick={props.setFullScreen} id='closesearch' />
        <form id='sm-bibsac-search-box' onSubmit={newSearch}>
                <input 
                    id='sm-bibsac-search-input'
                    placeholder='Search'
                    autoComplete="off"
                    defaultValue={props.searchTerm}
                    className={(props.darkMode ? 'darkmode' : '')}
                    onChange={function(e) {
                        if (e.target.value === "") {
                          setShowClearSearch('hidden')
                        }
                        else {
                          setShowClearSearch('')
                        }
                        setSearchTerm(e.target.value)
                      }}
                />
                <IoMdClose
                    onClick={function(e) {
                        setShowClearSearch('hidden')
                        document.querySelector("#sm-bibsac-search-input").value = ''
                        setSearchTerm('')
                    }}
                    id='sm-clearsearchinput'
                    className={showClearSearch}
                />
                <IoIosSearch id='sm-bibsac-search-icon'/>
            </form>
        <div id='resultscontainer'>
            {results.map((result, index) => {
                return (
                    <SearchResults finalResults={finalResults} setFinalResults={setFinalResults} key={index} result={result}  setChapterToSee={props.setChapterToSee} setDocToSee={props.setDocToSee} setHighlights={props.setHighlights} setFullScreen={props.setFullScreen}/>
                )
            })}
        </div>
        </div>
        <div id='searchLoader' className={searchLoader}><Spinner size='xl'/></div>
    </div>
  )
}

export default Search