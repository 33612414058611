import StyledFirebaseAuth from "./StyledFirebaseAuth.tsx";
import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    signOut,
} from "firebase/auth";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { doc, getFirestore, setDoc } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDLZv0TaYrhBc4ryFDZYLSbGgUF31-ASiA",
    authDomain: "biblia-sacra.firebaseapp.com",
    projectId: "biblia-sacra",
    storageBucket: "biblia-sacra.appspot.com",
    messagingSenderId: "312646624571",
    appId: "1:312646624571:web:54d4de1e22248d8689031e",
    measurementId: "G-SB0ZLNFEWX"
};

  const app = firebase.initializeApp(firebaseConfig);
  export const fbfsdb = getFirestore(app);
  const auth = getAuth(app);
  const googleProvider = new GoogleAuthProvider();
  const firebaseUiConfig = {
    callbacks: {
      signInSuccessWithAuthResult: function (authResult, redirectUrl) {
        // this is a new user, so add them to the firestore users collection!
        if (authResult.additionalUserInfo.isNewUser) {
          setDoc(doc(fbfsdb, `users/${authResult.user.uid}`), {
            displayName: authResult.user.displayName,
            email: authResult.user.email,
            subscriptionType: 'standard',
            currentDay: 0
          }).then((data) => {
            // create klaviyo user
            let klaviyoUrl = `https://biblia-sacra-be-2q23fvkbda-uc.a.run.app/createKlaviyoUser`
            var ckurequestOptions = {
              method: 'GET',
              headers: {
                "uid": authResult.user.uid
              }
            }

            fetch(klaviyoUrl, ckurequestOptions)
          }).catch((err) => {console.log('err', err);})
        }
        return false;
      },
      uiShown: () => {
      },
    },
    signInFlow: 'popup',
    signInSuccessUrl: '/',
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID
    ]
  };
  const signInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
    } catch (err) {
      console.error(err);
    }
  };
  const logout = () => {
    signOut(auth);
  };
  const LoginOptions = () => {
    return (
      <StyledFirebaseAuth uiConfig={firebaseUiConfig} firebaseAuth={firebase.auth()} />
    )
  }
  export {
    app,
    auth,
    signInWithGoogle,
    LoginOptions,
    logout,
  };